import React from "react"

import SEO from "../components/seo"
import styles from "./styles.module.scss"
import Header from "../components/header"
import Ticker from "react-ticker"
import Confetti from "../components/confetti"
import { Link } from "gatsby"

export default class IndexPage extends React.Component {
  // Theme Colors
  colors = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
  }

  constructor(props) {
    super(props)
    this.state = {
      birthdays: ["27-05-1997", "27-01-1997", "01-07-1991"],
    }
  }

  // The Fisher-Yates (aka Knuth) Shuffle
  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  async getBirthdaysFromAPI() {
    const response = await fetch(process.env.USERS_API_URL)
    const data = await response.json()
    const birthdays = data.users.map(user => {
      return user.birthday
    })
    this.setState({ birthdays: birthdays })
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      this.getBirthdaysFromAPI()
    }
  }

  randomBirthday() {
    const { birthdays } = this.state
    const shuffledBirthdays = this.shuffleArray(birthdays)
    return (
      <p style={{ whiteSpace: "nowrap" }}>
        {shuffledBirthdays.map(birthday => {
          var replacedBirthday = birthday.split("-").join(".")

          // Loop through colors
          for (let index = 0; index <= 9; index++) {
            var regex = new RegExp(index, "g")
            replacedBirthday = replacedBirthday.replace(
              regex,
              `<span class="${this.colors[index]}">${index}</span>`
            )
          }

          return (
            <span
              key={replacedBirthday}
              dangerouslySetInnerHTML={{
                __html: `happybirthdayto.${replacedBirthday}`,
              }}
            ></span>
          )
        })}
      </p>
    )
  }

  render() {
    return (
      <>
        <SEO title="Home" />
        <Header />
        <Confetti />
        <section className={styles.home}>
          <div className={styles.home__container}>
            <div className={styles.home__container__intro}>
              <p>Dear [name],</p>
              <p>
                Welcome to the online platform of the happybirthdayto.[name]
                institute. This is the meeting point for birthday-celebrators,
                birthday-planners, birthday-fans, birthday- observers,
                birthday-haters, birthday-forgetters, birthday-believers,
                birthday-emo’s, birthday-dreamers, birthday-marketeers,
                birthday-ignorers, birthday-fakers, birthday- experts and other
                sorts of birthday-human. Together, we are working on a never
                ending collaborative daily life philosophy, a.k.a. the
                Philosophy of Birthdays.
              </p>
              <p>Feel Invited!</p>
            </div>
            <div className={styles.home__container__links}>
              <Link
                to="/about/"
                className={styles.home__container__links__item}
              >
                <img
                  src="/home/cloth.png"
                  alt="beneath the decorated layer"
                ></img>
                <button>beneath the decorated layer</button>
              </Link>
              <Link
                to="/contact/"
                className={styles.home__container__links__item}
              >
                <img
                  src="/home/laptop.png"
                  alt="become part of the philosophy of birthdays"
                ></img>
                <button>become part of the philosophy of birthdays</button>
              </Link>
              <Link
                to="/enter-the-institute/"
                className={styles.home__container__links__item}
              >
                <img src="/home/door.png" alt="enter the institute"></img>
                <button>enter the institute</button>
              </Link>
            </div>
          </div>
          <div className={styles.home__marquees}>
            <Ticker speed={5} direction="toRight">
              {() => this.randomBirthday()}
            </Ticker>
            <Ticker speed={3} direction="toLeft">
              {() => this.randomBirthday()}
            </Ticker>
            <Ticker speed={4} direction="toLeft">
              {() => this.randomBirthday()}
            </Ticker>
            <Ticker speed={8} direction="toRight">
              {() => this.randomBirthday()}
            </Ticker>
            <Ticker speed={6} direction="toLeft">
              {() => this.randomBirthday()}
            </Ticker>
          </div>
        </section>
      </>
    )
  }
}
